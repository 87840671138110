<template>
  <div class='container'>
    <img class="header-bg" src="@/assets/my/head-bg.png" alt="">
    <div class="content">
      <div class="header">
        <img src="@/assets/my/head.png" alt="" style="width: 12vw;height: 12vw;border-radius: 13.3333vw;">
        <div style="flex: 1;margin: 0 5.3333vw;">
         <p style="font-size: 4.2667vw;font-weight: 550;margin-bottom: 1.3333vw;">{{ userInfo.org && userInfo.org.orgName }}</p>
         <p style="color: #919397;font-size: 2.9333vw;">ID：{{ userInfo.org && userInfo.org.id }}</p>
         <!-- <p style="color: #919397;font-size: 2.9333vw;">ID：25FSD65</p> -->
        </div>
        <div>
          <img src="@/assets/my/setting.png" alt="" style="width: 4vw;height: 4vw;margin-right: 2.6667vw;" @click="$router.push('message')">
          <img src="@/assets/my/remind.png" alt="" style="width: 4vw;height: 4vw;margin-right: 1.3333vw;">
        </div>
     </div>
     <div class="box" v-if="userInfo.isAppointment===1" @click="$router.push('appointment')">
       <div class="box-title">
         <p>预约咨询管理</p>
         <div style="display: flex;align-items: center;">
           <p style="mask-repeat: 1.6vw;">开启</p>
           <van-icon name="arrow" size="6.4vw"/>
         </div>
       </div>
     </div>
      <div class="box">
        <div class="box-title">
          <p>危机干预名单</p>
        </div>
        <div class="crisis">
          <div class="crisis-content" v-for="item in crisis" :key="item.id" @click="$router.push({path:item.url,query:{active:item.query}})">
            <img :src="require(`@/assets/my/${item.img}`)" alt="" style="width: 9.3333vw;height: 9.3333vw;margin-bottom:2.6667vw;">
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <p>心理教学</p>
        </div>
        <div class="crisis">
          <div class="crisis-content" v-for="item in teaching" :key="item.id" @click="$router.push(item.url)">
            <img :src="require(`@/assets/my/${item.img}`)" alt="" style="width: 8vw;height: 8vw;margin-bottom:2.6667vw;">
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <tabbar :activeIndex="3"></tabbar>
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import {getInfo} from '@/api/user.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      userInfo:'',
      crisis:[
        {id:1,title:'一级危机',img:'warn1.png',url:'/my/crisis',query:1},
        {id:2,title:'二级危机',img:'warn2.png',url:'/my/crisis',query:2},
        {id:3,title:'三级危机',img:'warn3.png',url:'/my/crisis',query:3},
        {id:4,title:'危机解除',img:'warn4.png',url:'/my/crisisRelieve',query:''},
      ],
      teaching:[
        {id:1,title:'心理教学资源',img:'teacher1.png',url:'psychologica'},
        {id:2,title:'学生心理课堂',img:'teacher2.png',url:'student'},
        {id:3,title:'家庭教育课程',img:'teacher3.png',url:'family'},
      ]
    }
  },
  created(){
    this.getUserInfo()
  },
  methods:{
    async getUserInfo(){
      const res = await getInfo()
      this.userInfo=res.data
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 8vw 4vw 4vw 4vw;
  min-height: 100%;
  background-color: #fff;
  .header-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75.2vw;
  }
  .content{
    position: relative;
  }
  .header{
    // margin-bottom: 10.1333vw;
    padding-left: 4vw;
    display: flex;
    align-items: center;
  }
  .box{
    margin-top: 5.3333vw;
    padding: 4vw;
    border-radius: 2.6667vw;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
    .box-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 3.7333vw;
      font-weight: 550;
    }
    .crisis{
      margin-top: 4vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .crisis-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 2.9333vw;
        color: #666;
      }
    }
  }
}
</style>